@font-face
    font-family: 'OpenSans'
    font-weight: 200
    font-style: normal
    font-display: auto
    src: url('./fonts/openSans/OpenSans-Light.eot')
    src: url('./fonts/openSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Light.woff') format('woff'), url('./fonts/openSans/OpenSans-Light.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Light.svg#open_sanslight') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 300
    font-style: normal
    font-display: auto
    src: url('./fonts/openSans/OpenSans-Light.eot')
    src: url('./fonts/openSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Light.woff') format('woff'), url('./fonts/openSans/OpenSans-Light.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Light.svg#open_sanslight') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 400
    font-style: normal
    font-display: auto
    src: url('./fonts/openSans/OpenSans-Regular.eot')
    src: url('./fonts/openSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Regular.woff') format('woff'), url('./fonts/openSans/OpenSans-Regular.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Regular.svg#open_sansregular') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 500
    font-style: normal
    font-display: auto
    src: url('./fonts/openSans/OpenSans-Semibold.eot')
    src: url('./fonts/openSans/OpenSans-Semibold.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Semibold.woff') format('woff'), url('./fonts/openSans/OpenSans-Semibold.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Semibold.svg#open_sanssemibold') format('svg')

@font-face
    font-family: 'OpenSans'
    font-weight: 600
    font-style: normal
    font-display: auto
    src: url('./fonts/openSans/OpenSans-Bold.eot')
    src: url('./fonts/openSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/openSans/OpenSans-Bold.woff') format('woff'), url('./fonts/openSans/OpenSans-Bold.ttf') format('truetype'), url('./fonts/openSans/OpenSans-Bold.svg#open_sansbold') format('svg')

@font-face
    font-family: 'slick'
    src: url('./fonts/slick/slick.woff') format('woff'), url('./fonts/slick/slick.woff2') format('woff2')
    font-weight: normal
    font-style: normal
    font-display: auto

@font-face
    font-family: 'Brutal_Bold'
    src: url('./fonts/brutal/BrutalType-Bold/BrutalType-Bold.eot')
    src: url('./fonts/brutal/BrutalType-Bold/BrutalType-Bold.eot?#iefix') format('embedded-opentype'), url('./fonts/brutal/BrutalType-Bold/BrutalType-Bold.woff') format('woff'), url('./fonts/brutal/BrutalType-Bold/BrutalType-Bold.svg#BrutalType-Bold') format('svg')
    font-weight: 600
    font-style: normal
    font-display: auto

@font-face
    font-family: 'Brutal_Medium'
    src: url('./fonts/brutal/BrutalType-Medium/BrutalType-Medium.eot')
    src: url('./fonts/brutal/BrutalType-Medium/BrutalType-Medium.eot?#iefix') format('embedded-opentype'), url('./fonts/brutal/BrutalType-Medium/BrutalType-Medium.woff') format('woff'), url('./fonts/brutal/BrutalType-Medium/BrutalType-Medium.svg#BrutalType-Medium') format('svg')
    font-weight: 500
    font-style: normal
    font-display: auto

@font-face
    font-family: 'Brutal_Regular'
    src: url('./fonts/brutal/BrutalType-Regular/BrutalType-Regular.eot')
    src: url('./fonts/brutal/BrutalType-Regular/BrutalType-Regular.eot?#iefix') format('embedded-opentype'), url('./fonts/brutal/BrutalType-Regular/BrutalType-Regular.woff') format('woff'),  url('./fonts/brutal/BrutalType-Regular/BrutalType-Regular.svg#BrutalType') format('svg')
    font-weight: 400
    font-style: normal
    font-display: auto

@font-face
    font-family: 'Brutal_Light'
    src: url('./fonts/brutal/BrutalType-Light/BrutalType-Light.eot')
    src: url('./fonts/brutal/BrutalType-Light/BrutalType-Light.eot?#iefix') format('embedded-opentype'), url("./fonts/brutal/BrutalType-Light/BrutalType-Light.otf") format("opentype"), url('./fonts/brutal/BrutalType-Light/BrutalType-Light.woff') format('woff'), url('./fonts/brutal/BrutalType-Light/BrutalType-Light.svg#BrutalType-Light') format('svg')
    font-weight: 300
    font-style: normal
    font-display: auto

@font-face
    font-family: 'Brutal_ExtraLight'
    src: url('./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.eot')
    src: url('./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.eot?#iefix') format('embedded-opentype'), url('./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.woff') format('woff'), url('./fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.svg#BrutalType-ExtraLight') format('svg')
    font-weight: 200
    font-style: normal
    font-display: auto

@font-face
  font-family: 'Brutal'
  src: url('fonts/brutal/BrutalType-Bold/BrutalType-Bold.eot')
  src: url('fonts/brutal/BrutalType-Bold/BrutalType-Bold.eot?#iefix') format('embedded-opentype'), url('fonts/brutal/BrutalType-Bold/BrutalType-Bold.woff') format('woff'), url('fonts/brutal/BrutalType-Bold/BrutalType-Bold.svg#BrutalType-Bold') format('svg')
  font-weight: 600
  font-style: normal
  font-display: auto
  text-rendering: optimizeLegibility

@font-face
  font-family: 'Brutal'
  src: url('fonts/brutal/BrutalType-Medium/BrutalType-Medium.eot')
  src: url('fonts/brutal/BrutalType-Medium/BrutalType-Medium.eot?#iefix') format('embedded-opentype'), url('fonts/brutal/BrutalType-Medium/BrutalType-Medium.woff') format('woff'), url('fonts/brutal/BrutalType-Medium/BrutalType-Medium.svg#BrutalType-Medium') format('svg')
  font-weight: 500
  font-style: normal
  font-display: auto
  text-rendering: optimizeLegibility


@font-face
  font-family: 'Brutal'
  src: url('fonts/brutal/BrutalType-Regular/BrutalType-Regular.eot')
  src: url('fonts/brutal/BrutalType-Regular/BrutalType-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/brutal/BrutalType-Regular/BrutalType-Regular.woff') format('woff'), url('fonts/brutal/BrutalType-Regular/BrutalType-Regular.svg#BrutalType') format('svg')
  font-weight: 400
  font-style: normal
  font-display: auto
  text-rendering: optimizeLegibility

@font-face
  font-family: 'Brutal'
  src: url('fonts/brutal/BrutalType-Light/BrutalType-Light.eot')
  src: url('fonts/brutal/BrutalType-Light/BrutalType-Light.eot?#iefix') format('embedded-opentype'),url("./fonts/brutal/BrutalType-Light/BrutalType-Light.otf") format("opentype"),  url('fonts/brutal/BrutalType-Light/BrutalType-Light.woff') format('woff'), url('fonts/brutal/BrutalType-Light/BrutalType-Light.svg#BrutalType-Light') format('svg')
  font-weight: 300
  font-style: normal
  font-display: auto
  text-rendering: optimizeLegibility

@font-face
  font-family: 'Brutal'
  src: url('fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.eot')
  src: url('fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.eot?#iefix') format('embedded-opentype'), url('fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.woff') format('woff'), url('fonts/brutal/BrutalType-ExtraLight/BrutalType-ExtraLight.svg#BrutalType-ExtraLight') format('svg')
  font-weight: 200
  font-style: normal
  font-display: auto
  text-rendering: optimizeLegibility

@font-face
  font-family: 'Noto Sans JP'
  font-style: normal
  font-display: auto
  font-weight: 400
  src: url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-regular.eot')
  src: local('Noto Sans Japanese Regular'), local('NotoSansJapanese-Regular'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-regular.eot?#iefix') format('embedded-opentype'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-regular.woff2') format('woff2'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-regular.woff') format('woff'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-regular.svg#NotoSansJP') format('svg')


@font-face
  font-family: 'Noto Sans JP'
  font-style: normal
  font-display: auto
  font-weight: 900
  src: url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-900.eot')
  src: local('Noto Sans Japanese Black'), local('NotoSansJapanese-Black'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-900.eot?#iefix') format('embedded-opentype'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-900.woff2') format('woff2'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-900.woff') format('woff'), url('fonts/noto/noto-jp/noto-sans-jp-v18-latin_japanese-900.svg#NotoSansJP') format('svg')

@font-face
  font-family: 'Noto Sans KR'
  font-style: normal
  font-display: auto
  font-weight: 400
  src: url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-regular.eot')
  src: local('Noto Sans KR Regular'), local('NotoSansKR-Regular'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-regular.eot?#iefix') format('embedded-opentype'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-regular.woff2') format('woff2'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-regular.woff') format('woff'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-regular.svg#NotoSansKR') format('svg')

@font-face
  font-family: 'Noto Sans KR'
  font-style: normal
  font-display: auto
  font-weight: 900
  src: url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-900.eot')
  src: local('Noto Sans KR Black'), local('NotoSansKR-Black'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-900.eot?#iefix') format('embedded-opentype'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-900.woff2') format('woff2'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-900.woff') format('woff'), url('fonts/noto/noto-kr/noto-sans-kr-v8-latin_korean-900.svg#NotoSansKR') format('svg')

@font-face
  font-family: 'Noto Sans SC'
  font-style: normal
  font-display: auto
  font-weight: 400
  src: url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-regular.eot')
  src: local('Noto Sans SC Regular'), local('NotoSansSC-Regular'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-regular.eot?#iefix') format('embedded-opentype'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-regular.woff2') format('woff2'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-regular.woff') format('woff'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-regular.svg#NotoSansSC') format('svg')


@font-face
  font-family: 'Noto Sans SC'
  font-style: normal
  font-display: auto
  font-weight: 900
  src: url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-900.eot')
  src: local('Noto Sans SC Black'), local('NotoSansSC-Black'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-900.eot?#iefix') format('embedded-opentype'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-900.woff2') format('woff2'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-900.woff') format('woff'), url('fonts/noto/noto-sc/noto-sans-sc-v2-latin_chinese-simplified-900.svg#NotoSansSC') format('svg')

@font-face
  font-family: 'Fira_Mono_Regular'
  src: url('./fonts/fira/FiraMono-Regular.ttf')
  font-weight: 400
  font-style: normal
  font-display: auto

@font-face
  font-family: 'Fira_Mono_Medium'
  src: url('./fonts/fira/FiraMono-Medium.ttf')
  font-weight: 500
  font-style: normal
  font-display: auto

@font-face
  font-family: 'Fira_Mono_Bold'
  src: url('./fonts/fira/FiraMono-Bold.ttf')
  font-weight: 700
  font-style: normal
  font-display: auto
