/**
 * Custom bootstrap imports.  Comment out anything which you aren't using in your app to minimizing file sizes!
 */

$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/"
$link-color: #0aaff1

.ue-bs
    @import "~bootstrap-sass/assets/stylesheets/bootstrap/_variables"
    @import "~bootstrap-sass/assets/stylesheets/bootstrap/_mixins"

    @import "~bootstrap-sass/assets/stylesheets/bootstrap/_print"

    //Core CSS
    @import "~bootstrap-sass/assets/stylesheets/bootstrap/_scaffolding"

    @import "~bootstrap-sass/assets/stylesheets/bootstrap/_grid"

    //Utility classes
    @import "~bootstrap-sass/assets/stylesheets/bootstrap/_utilities"
    @import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities"
    @import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed"

    .container, .ue-react.container
        @media (min-width: 1200px)
            width: 1230px
            max-width: 1200px
            margin-left: auto
            margin-right: auto


    hr
        border-top: 1px solid rgb(34, 34, 34)